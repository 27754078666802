@import "./layout";
@import "./mixins";

.light-mode {
  --background: #fff;
  --background-floating: #fff;
  --background-form-selected: #00bfa5;
  --background-shadow: rgba(202, 202, 202, 0.5);
  --box-background: #f7f8f9;
  --divider-color: #e7e8e9;
  --text-color: #1c1c1e;
  --text-color-muted: #676767;
  --text-color-accent: #e91b0c;
  --text-color-link: #d81b60;
  --text-form-selected: #1c1c1e;
  --footer-background: linear-gradient(90deg, #f44336, #e91e63);
}

.dark-mode {
  --background: #121212;
  --background-floating: #2e2e2e;
  --background-form-selected: #00bfa5;
  --background-shadow: rgba(28, 28, 30, 0.5);
  --box-background: #1c1c1e;
  --divider-color: #1c1c1e;
  --text-color: rgba(255, 255, 255, 0.87);
  --text-color-accent: #e91b0c;
  --text-color-link: #d81b60;
  --text-color-muted: #aeaeb2;
  --text-form-selected: #1c1c1e;
  --footer-background: #1c1c1e;
}

html,
body {
  background: var(--background, #fff);
  font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  line-height: 1.61803398875;
  font-weight: 400;
  color: var(--text-color, #1c1c1e);
  letter-spacing: 0.18px;
}
html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
ul,
ol {
  list-style: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
.ui-h2 {
  color: var(--text-color, #1c1c1e);
  font-weight: 400;
  letter-spacing: 0.27px;
  line-height: 1.42857143;
}
iframe {
  width: 100%;
}
.visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
}

.AppLayoutContainer {
  clear: both;
  margin: 0 auto;
  min-height: 80vh;
  position: relative;
}

.AppLayout {
  margin: 0 auto;
  overflow: hidden;
  max-width: 1200px;
  padding: 0 36px;
  @include mq-down(md) {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.AppHeaderInner {
  transition: 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.AppLogo {
  align-items: center;
  padding-right: 16px;
  height: 60px;
  display: inline-block;
  position: relative;
  z-index: 45;
}

.ui-AppHeaderLogo {
  align-items: center;
  padding-right: 16px;
  display: flex;
  height: 56px;
}

.ui-AppHeaderLogo svg {
  position: relative;
  top: -3px;
}
.DetailTitle {
  font-weight: 700;
  font-size: 18px;
  margin-top: 15px;
  margin-bottom: 15px;
  display: block;
}

.ExternalLink {
  border-bottom: 2px solid var(--text-color-link, #000);
  color: var(--text-color-link, #000);
  font-size: 14.4px;
  font-weight: 700;
  padding-bottom: 1px;
}

.Button {
  align-items: center;
  background: linear-gradient(-90deg, #ec1e79, #f05a24);
  border: 0;
  border-radius: 22px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  height: 44px;
  display: flex;
  justify-content: center;
  outline: 0;
  overflow: hidden;
  padding: 0 22px;
  position: relative;
  width: 100%;
}

.Button--small {
  border-radius: 16px;
  font-size: 14px;
  height: 32px;
  line-height: 14px;
  padding: 0 16px;
}

.ButtonPlaceholder {
  background: #e2e2e2;
  cursor: default;
}

.ButtonText {
  position: relative;
  white-space: nowrap;
  z-index: 1;
}

.Button--phantom {
  background: transparent;
  color: var(--text-color-link, #000);
  border: 1px solid var(--text-color-link, #000);
}

.Button--dark {
  background: linear-gradient(-90deg, #434343, #1f1f1f);
}

.Button:hover {
  transition: transform 0.3s;
  transform: translate3d(0, -1px, 0);
}

.Button--large {
  height: 56px;
  border-radius: 28px;
  font-size: 22.5px;
}

.Button[disabled],
.Button[disabled]:hover {
  background: #ddd;
  color: #aaa;
  cursor: default;
}
.ProceedBtnContainer {
  position: relative;
}
.ProceedBtnContainer:hover {
  transition: transform 0.3s;
  transform: translate3d(0, -1px, 0);
}
.UIT {
  letter-spacing: 0.21px;
  color: inherit;
}

.UITS1 {
  font-size: 16px;
}

.UITS2 {
  font-size: 11.52px;
}

.UITS3 {
  font-size: 14.4px;
}

.UITS0 {
  font-size: 18px;
}

.UITSL {
  font-size: 22.5px;
}
.UITS30 {
  font-size: 30.5px;
  @media screen and (max-width: 640px) {
    font-size: 24px;
    line-height: 1.5em;
  }
}
.UITSLL {
  font-size: 35.16px;
}

.UITSLLL {
  font-size: 43.95px;
}

.UITP1 {
  white-space: pre-line;
}

.UITU1 {
  text-decoration: underline;
}

.UITW0 {
  font-weight: 400;
}

.UITW1 {
  font-weight: 700;
}

.UITC1 {
  color: #007571;
}

.UITC2 {
  color: var(--text-color-muted);
}

.UITC4 {
  color: var(--text-color-accent);
}

.UITC3 {
  color: #42bf70;
}
.HomeCityHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 60px;
  @media screen and (max-width: 640px) {
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
    margin-bottom: 5px;
  }
}
.HomeCityHeader a {
  font-size: 14px;
  font-weight: normal;
  color: #f44336;
  @media screen and (max-width: 640px) {
    font-size: 16px;
  }
}
.Message {
  border-radius: 3px;
  padding: 8px 12px;
}

.Message--warning {
  background-color: #fff2e3;
  border: 1px solid #ffdbad;
  color: #ec4817;
}

.Message--info {
  background-color: #edf8f7;
  border: 1px solid #edf8f7;
  color: #007571;
}

.Checkbox {
  display: inline-flex;
  width: 22px;
  height: 22px;
  background: #fff;
  border: 2px solid #d7d8d9;
  border-radius: 2px;
}

.Checkbox--checked {
  background: var(--background-form-selected, #000);
  border-color: var(--background-form-selected, #000);
}

.react-phone-number-input__icon-image {
  display: block;
}

.PhoneInputInput {
  border: 1px solid #ddd;
  border-radius: 3px;
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  height: 44px;
  outline: 0;
  padding: 8px 14px;
  width: 100%;
}

.react-responsive-modal-root {
  z-index: 10000 !important;
}
.react-responsive-modal-overlay,
.react-responsive-modal-container,
.react-responsive-modal-modal {
  animation-fill-mode: forwards !important;
}
.react-responsive-modal-modal {
  padding: 2.2rem;
}

.ModalScreen {
  position: fixed;
  background: #fff;
  top: 0;
  left: 50%;
  width: 100%;
  bottom: 0;
  z-index: 100;
}

.ModalScreenHeader {
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.ModalScreenContent {
  position: relative;
  height: 100%;
  padding-bottom: 56px;
  overflow-y: auto;
}

.ReactModalOverlay {
  background-color: transparent;
}

.ReactModalContent {
  background-color: transparent;
  border: 0;
  left: 0;
  height: 100%;
  padding: 0;
  position: fixed;
  margin: 0;
  top: 0;
  width: 100%;
}

.ReactModalInner {
  background: #fff;
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
  top: 0;
}
.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.8) !important;
  z-index: 1000;
}
.ReactModal__Content {
  bottom: auto !important;
  left: 50% !important;
  margin-right: -50% !important;
  min-width: 300px !important;
  padding: 0 !important;
  right: auto !important;
  top: 50% !important;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.TimePicker {
  display: inline-flex;
  flex-wrap: wrap;
  margin: -3px -6px;
  max-width: 300px;
}

.TimePicker li {
  margin: 6px;
}

.TimePickerOption {
  align-items: center;
  background: #fff;
  border: 1px solid #d7d8d9;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-weight: 700;
  height: 56px;
  justify-content: center;
  position: relative;
  width: 88px;
}

.TimePickerOptionDisabledReason {
  font-size: 10px;
  text-transform: uppercase;
  line-height: 1em;
  text-align: center;
}
.TimePickerOptionLowSeats {
  font-size: 10px;
  font-weight: bold;
  color: #ec1e79;
  text-transform: uppercase;
}

.TimePickerOption[aria-disabled="true"] {
  background: #d7d8d9;
  box-shadow: none;
  cursor: default;
  opacity: 0.4;
}

.TimePickerOption--selected {
  background: var(--background-form-selected, "#000");
  border-color: var(--background-form-selected, "#000");
  box-shadow: none;
  color: var(--text-form-selected, "#000");
  cursor: default;
  .TimePickerOptionLowSeats {
    color: #fff;
  }
}

.TimePickerOption:hover {
  border-color: var(--background-form-selected, "#000");
}

.TimePickerOption[aria-disabled="true"]:hover {
  border-color: #d7d8d9;
}

.TimePickerOption--selected:hover {
  border-color: var(--background-form-selected, "#000");
}

.ReservationDatepicker .react-datepicker__month {
  margin: 0;
  padding: 0;
}

.ReservationDatepicker .react-datepicker__month-container {
  float: none;
}

.ReservationDatepicker .react-datepicker__header {
  background-color: #fff;
  border-bottom: 1px solid #e2e4e4;
  padding-top: 0;
}

.ReservationDatepicker .react-datepicker__current-month {
  align-items: center;
  display: flex;
  height: 44px;
  justify-content: center;
}

.ReservationDatepicker .react-datepicker__navigation {
  top: 14px;
}

.ReservationDatepicker .react-datepicker__day-name,
.ReservationDatepicker .react-datepicker__day,
.ReservationDatepicker .react-datepicker__time-name {
  height: 44px;
  line-height: 44px;
  margin: 0;
  width: 14.2857142857%;
}

.ReservationDatepicker .react-datepicker__day {
  border-radius: 0;
}

.ReservationDatepicker .react-datepicker__day:hover {
  font-weight: 700;
  background: transparent;
  color: var(--background-form-selected, "#000");
}

.ReservationDatepicker .react-datepicker__day--selected,
.ReservationDatepicker .react-datepicker__day--in-selecting-range,
.ReservationDatepicker .react-datepicker__day--in-range {
  background-color: var(--background-form-selected, "#000");
  color: var(--text-form-selected, "#000");
  font-weight: 700;
}

.ReservationDatepicker .react-datepicker__day--selected:hover,
.ReservationDatepicker .react-datepicker__day--in-selecting-range:hover,
.ReservationDatepicker .react-datepicker__day--in-range:hover {
  background-color: var(--background-form-selected, "#000");
  color: var(--text-form-selected, "#000");
  border-radius: 0;
}

.ReservationDatepicker .react-datepicker__full,
.ReservationDatepicker .react-datepicker__day--today {
  position: relative;
}

.ReservationDatepicker .react-datepicker__full::after,
.ReservationDatepicker .react-datepicker__day--today::after {
  bottom: 3px;
  font-size: 8px;
  font-weight: bold;
  left: 0;
  line-height: 1;
  position: absolute;
  right: 0;
  text-transform: uppercase;
}

.ReservationDatepicker .react-datepicker__full {
  color: #e3e3e3;
}

.ReservationDatepicker .react-datepicker__full::after {
  content: "full";
}

.ReservationDatepicker .react-datepicker__day--today::after {
  content: "today";
}

.ReservationDatepicker .react-datepicker__day--disabled {
  background: #e5e5e5;
  color: #878787;
}

.ReservationDatepicker .react-datepicker__day--disabled:hover {
  background: #e5e5e5;
  color: #878787;
  font-weight: 500;
  border-radius: 0;
}

.ReservationDatepicker .react-datepicker {
  border-color: #e2e4e4;
  display: block;
  font-size: 14px;
  font-weight: 400;
  max-width: 320px;
}
.ReservationDatepicker .react-datepicker-popper {
  z-index: 5;
  width: 100%;
}

.ReservationDatepicker
  .react-datepicker__day--keyboard-selected[aria-disabled="false"] {
  background: transparent;
  color: inherit;
}

.ReservationDatepicker .react-datepicker__input-container input {
  width: 150px;
  padding: 10px 13px;
  border-radius: 6px;
  margin-top: 5px;
  border: 2px solid #03bfa5;
  cursor: pointer;
}
.ReservationDatepicker .react-datepicker__day--highlighted {
  background: rgba(63, 81, 181, 0.5);
  color: #fff;
}

.ReservationDatepicker-TimePicker {
  min-height: 56px;
  padding-top: 15px;
}

.ReservationDatepicker-InstantBooking {
  padding-top: 12px;
  display: flex;
  justify-content: center;
  max-width: 300px;
}

.ReservationDatepickerTimeZone {
  color: #009688;
}

.ui-ContactUsLink {
  color: #d81b60;
}

.VideoContainer {
  background-color: transparent;
  height: 100%;
  width: 100%;
  position: absolute;
}
.VideoContainer video {
  width: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  height: 100%;
}
.fadeOut {
  opacity: 0;
  width: 0;
  height: 0;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
}
.fadeIn {
  opacity: 1;
  transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
}

#HomeCarouselContainer .react-multiple-carousel__arrow--left {
  left: 40%;
  bottom: 5%;
  background: #fff;
  @media screen and (max-width: 640px) {
    left: 30%;
  }
}
#HomeCarouselContainer .react-multiple-carousel__arrow--right {
  right: 40%;
  bottom: 5%;
  background: #fff;
  @media screen and (max-width: 640px) {
    right: 30%;
  }
}
#HomeCarouselContainer button::before {
  color: #000;
  font-weight: bold;
}

.ui-Link {
  appearance: none;
  background: transparent;
  border: 0px;
  color: var(--text-color-link, #000);
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: 90%;
  font-weight: 700;
  outline: 0px;
  text-decoration: none;
  text-transform: uppercase;
}
.react-responsive-modal-closeButton {
  background-color: #fff !important;
  padding: 10px !important;
  border: 1px solid #444 !important;
  border-radius: 50%;
  z-index: 100;
}
.react-responsive-modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.StripeElement {
  box-sizing: border-box;

  height: 40px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.AppTopBar {
  box-shadow: 2px 2px 3px 2px rgba(100, 100, 100, 0.12);
  background: #fff;
  transition: transform 0.4s;
}

.mui-detailStickyContainer {
  transform: scale(0);
  transition: transform 0.2s;
  bottom: 0;
  height: 0px;
  width: 100%;
  left: 0;
  opacity: 0;
  display: none;

  @media screen and (max-width: 839px) {
    display: block;
    opacity: 1;
  }
}

.mui-detailStickyContainerShow {
  transform: scale(1);
  position: fixed;
  z-index: 999;
  background-color: #fff;
  height: 70px;
  -webkit-transition: height 0.2s linear;
  -moz-transition: height 0.2s linear;
  -ms-transition: height 0.2s linear;
  -o-transition: height 0.2s linear;
  transition: height 0.2s linear;
}

.TopBarDetailInner {
  height: 70px;
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  align-items: center;
  line-height: 1.2em;
}
.TopBarDetailInner h3 {
  margin: 0;
  width: 60%;
  margin-right: 25px;
  color: #282828;
  @media screen and (max-width: 640px) {
    display: none;
  }
}
.TopBarDetailInner .Button {
  width: 150px;
  border-radius: 80px;
  font-size: 16px;
  padding: 0px;
}

@media only screen and (max-width: 640px) {
  .swiper-wrapper {
    align-items: center;
  }
}

.swiper-button-next,
.swiper-button-prev {
  width: 40px !important;
  height: 40px !important;
  background-color: #fff;
  border-radius: 50%;
  &::after {
    display: none;
  }
  &:hover {
    opacity: 0.9;
  }
}
.swiper-button-prev {
  background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cpath fill='%231668e3' d='M15.41 7.41 14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z'%3E%3C/path%3E%3C/svg%3E") !important;
}

.swiper-button-next {
  background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cpath fill='%231668e3' d='M10 6 8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z'%3E%3C/path%3E%3C/svg%3E") !important;
}

.ConfirmationMessage {
  h1 {
    font-size: 36px;
    color: #07917e;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: bold;
    @include mq-down(md) {
      font-size: 26px;
      svg {
        top: 10px;
      }
    }
  }
}

.swiper-scrollbar {
  --swiper-scrollbar-bottom: 0;
  --swiper-scrollbar-drag-bg-color: #dda3b6;
  --swiper-scrollbar-size: 5px;
}
.guideSwiperButtonNext.swiper-button-disabled,
.guideSwiperButtonPrev.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}

.guideSwiperButtonPrev.swiper-button-prev {
  @include mq-down(fm) {
    left: 30% !important;
    right: auto !important;
    top: auto !important;
    bottom: 10% !important;
  }
}
.guideSwiperButtonNext.swiper-button-next {
  @include mq-down(fm) {
    left: auto !important;
    right: 30% !important;
    top: auto !important;
    bottom: 10% !important;
  }
}
