.FooterToursContainer {
  align-items: center;
  display: flex;
  padding: 18px 0;
  margin-bottom: 20px;
  .FooterToursList {
    display: flex;
    gap: 40px;
    a {
      font-size: 14px;
      &:hover {
        color: #e91e63;
      }
    }
    b {
      text-decoration: underline;
    }
  }
  .FooterToursListTitle {
    margin-right: 56px;
    width: 240px;
  }
}

@media screen and (max-width: 839px) {
  .FooterToursContainer {
    flex-direction: column;
    padding: 7px;
    .FooterToursList {
      flex-wrap: wrap;
      width: 100%;
      padding: 7px;
      gap: 30px;
    }
    .FooterToursListTitle {
      margin-right: 0;
      width: auto;
      margin-bottom: 15px;
    }
  }
}
