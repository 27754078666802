.SelectButton {
  border: 0;
  background: rgba(0, 0, 0, 0.1);
  color: #fff;
  cursor: pointer;
  font-size: inherit;
  height: 44px;
  border-radius: 22px;
  outline: 0;
  padding: 0 22px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 12px;
  white-space: nowrap;
  display: none;
}

.SelectButton path {
  stroke: #fff;
}

@media only screen and (max-width: 940px) {
  .SelectButton {
    display: flex;
  }
}
