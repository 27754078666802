@import "../../../styles/layout";

.CheckoutHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

ol.stepper {
  --default-b: lightgrey;
  --default-c: black;
  --active-b: #f44336;
  --active-c: white;
  --circle: 2em; /* size of circle */
  --b: 5px; /* line thickness */

  display: flex;
  list-style: none;
  justify-content: space-between;
  counter-reset: step;
  margin: 20px;
  padding: 0;
  font-size: 16px;
  font-weight: bold;
  counter-reset: step;
  overflow: hidden;
  @include mq-down(fm) {
    margin: 0;
    padding: 20px 30px;
  }
  @include mq-down(sm) {
    font-size: 13px;
    padding: 20px;
  }
}
ol.stepper li {
  display: grid;
  place-items: center;
  gap: 5px;
  width: 80px;
  position: relative;
  @include mq-down(fm) {
    width: 60px;
  }
  @include mq-down(sm) {
    width: 50px;
  }
  span {
    font-size: 12px;
    @include mq-down(fm) {
      font-size: 11px;
    }
    @include mq-down(sm) {
      font-size: 10px;
    }
  }
  &.active {
    &::before {
      background: var(--active-b);
    }
  }

  &::before {
    content: counter(step) " ";
    counter-increment: step;
    display: grid;
    place-content: center;
    aspect-ratio: 1;
    height: var(--circle);
    box-sizing: border-box;
    background: var(--default-b);
    color: var(--active-c);
    border-radius: 50%;
    z-index: 1;
  }
  .bar {
    width: 70%;
    height: 3px;
    background: #ccc;
    position: absolute;
    top: 30%;
    right: -30%;
    &.active {
      background: var(--active-b);
    }
    @include mq-down(fm) {
      right: -40%;
    }
  }
}
.CurrencyDropDown {
  select,
  svg {
    color: #000;
    fill: #000;
  }
}
.HeaderBackBtn {
  cursor: pointer;
  @include mq-down(sm) {
    flex-shrink: 0;
  }
  @include mq-down(xs) {
    flex-shrink: inherit;
  }
  &:hover {
    opacity: 0.8;
  }
  strong {
    font-size: 16px;
    @include mq-down(sm) {
      font-size: 13px;
    }
  }
  svg {
    margin-right: 10px;
    position: relative;
    top: 2px;
  }
}
