.FooterNavigationContainer {
  align-items: center;
  display: flex;
  padding: 18px 0;
}

.FooterNavigation {
  display: flex;
  flex-wrap: wrap;
}

.FooterNavigation a {
  color: inherit;
  font-size: 14.4px;
  height: 44px;
  padding: 0 16px;
  align-items: center;
  display: inline-flex;
  text-decoration: none;
}

.FooterNavigation a:hover {
  color: #e91e63;
}

.FooterNavigationTitle {
  margin-right: 56px;
  white-space: nowrap;
}

@media screen and (max-width: 767px) {
  .FooterNavigationContainer {
    flex-direction: column;
  }

  .FooterNavigationTitle {
    margin-right: 0;
  }
}
