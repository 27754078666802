.CurrencyPicker {
  align-items: center;
  display: flex;
  position: relative;
}

.CurrencyPicker select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 0;
  color: #fff;
  cursor: pointer;
  font-size: 14.4px;
  height: 56px;
  outline: 0;
  padding: 0 16px;
}

.CurrencyPicker option {
  color: #000;
}

.CurrencyPickerIcon {
  color: #fff;
  line-height: 0.5;
  pointer-events: none;
  position: absolute;
  right: 0;
}
