.cityTab {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.1);
  border-style: solid;
  border-radius: 3px;
  border-width: 1px;
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px 0;
  display: inline-block;
  height: 73px;
  margin-bottom: 4px;
  overflow: hidden;
  padding: 0;
  text-align: left;
  width: 200px;
  cursor: pointer;
}

.CityTabContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.CityTabContainer a {
  width: 100%;
  max-width: 280px;
}

.cityTab span {
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  line-height: 25px;
  padding: 23px 15px;
}

.cityTab.highlighted,
.cityTab:hover {
  background: #ec1e79;
}

.cityTab.highlighted span,
.cityTab:hover span {
  color: #fff;
}

.cityTabImage {
  background-position: center center;
  background-size: contain;
  float: left;
  height: 71px;
  width: 75px;
  position: relative;
}

.cityTabImage img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

@media screen and (max-width: 640px) {
  .cityTabImage {
    height: 48px;
    width: 48px;
  }
  .cityTab {
    margin: 0;
    height: 49px;
    width: 48%;
    justify-content: space-between;
  }
  .cityTab span {
    font-size: 13px;
    padding: 13px 10px;
  }
}
@media screen and (max-width: 350px) {
  .cityTab {
    width: 100%;
  }
}
