.FooterPartner {
  box-shadow: 1px 1px 4px #444;
  display: inline-block;
  margin: 0;
  margin-bottom: 10px;
  margin-right: 10px;
  vertical-align: top;
  width: 75px;
  height: 36px;
  position: relative;
}

.FooterPartnerList {
  width: 216px;
}
