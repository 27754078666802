$selected-background: #00bfa54d;
$dark-selected-background: #00bfa5;
$transparant-selected-background: #ffc2c000;

.DateRangerPicker {
  border-radius: 10px;
}

.DualCalender {
  display: flex;
  gap: 48px;
  position: relative;
}

.Weekday {
  text-transform: capitalize;
  color: grey;
}

.Calender {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-row-gap: 4px;

  text-align: center;
}

.Date {
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1/1;
  border-radius: 50%;
}

.DateContainer {
  width: 48px;
  height: 44px;
  padding-left: 2px;
  padding-right: 2px;
}

.Date:not(.Selectable) {
  color: lightgray;
}

.Date.Selectable:hover {
  border: 1px black solid;
}

.DateContainer.Selectable {
  cursor: pointer;
}

.DateContainer:not(.Selectable) {
  cursor: not-allowed;
}

.Selected {
  color: white;
  background-color: $dark-selected-background;
}

.BetweenSelected,
.FirstSelected,
.LastSelected {
  background-color: $selected-background;
}

.FirstSelected {
  background: linear-gradient(
    to left,
    $selected-background 50%,
    $transparant-selected-background 50%
  );
}

.LastSelected {
  background: linear-gradient(
    to right,
    $selected-background 50%,
    $transparant-selected-background 50%
  );
}

.FirstSelected.LastSelected {
  background: none;
}

.Calender .BetweenSelected:first-of-type::before {
  content: "";
  display: block; /* Ensures that the content appears on a new line */
  width: 16px;
  height: 44px;
  background: linear-gradient(
    to left,
    $selected-background,
    $transparant-selected-background
  );
  position: absolute;
  transform: translate(-18px);
}

.Calender .BetweenSelected:last-of-type::after {
  content: "";
  display: block; /* Ensures that the content appears on a new line */
  width: 16px;
  height: 44px;
  background: linear-gradient(
    to right,
    $selected-background,
    $transparant-selected-background
  );
  position: absolute;
  transform: translate(46px, -100%);
}

.ChangeMonthButton {
  z-index: 10;
  position: absolute;
  top: -2px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.ChangeMonthButton:last-of-type {
  right: 0;
}

.ChangeMonthButton:hover {
  background-color: #e4e4e4;
}

.CalenderCarouselContainer {
  position: relative;
  overflow: hidden;
}

.CalenderCarousel {
  display: flex;
  gap: 48px;
  position: relative;
  transition: left 0.5s ease;
}
