.AppFooter {
  background: #e5e5e5;
}

.AppFooterInner {
  background: transparent;
  overflow: hidden;
  padding: 18px 0;
}

.AppFooter a {
  text-decoration: none;
}

.AppFooterNav {
  display: flex;
  justify-content: flex-start;
  font-size: 13px;
  flex-wrap: wrap;
  align-items: center;
}

.FooterHeading {
  font-size: 21px;
  font-weight: bold;
  line-height: 1.28;
  margin: 10px 0;
}
.AppFooterTextLinks {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.AppFooterTextLinks div {
  min-width: 200px;
}
.AppFooterTextLinks b {
  font-size: 16px;
  text-decoration: underline;
}
.AppFooterTextLinks a:hover {
  color: #f4bc36;
}

@media screen and (max-width: 1140px) {
  .AppFooterInner {
    flex-direction: column;
  }
}

@media screen and (max-width: 640px) {
  .AppFooterNav {
    display: none;
  }
}

.AppFooterContact li,
.AppFooterSocial li {
  display: block;
  font-size: 14px;
  margin: 5px 0;
}

.AppFooterSocial svg,
.AppFooterContact svg {
  margin-right: 10px;
  margin-top: -5px;
}

@media screen and (max-width: 727px) {
  .AppFooterSocial li {
    display: inline-block;
  }
}

.AppFooterContact .ContactAddress span {
  display: inline-block;
}

.ContactAddress p {
  margin-top: 10px;
}

.AppFooter .AppLayout h1 {
  margin-bottom: 10px;
}

.AppFooter .AppLayout p {
  font-size: 12px;
}

.BottomFooter {
  background: #fff;
  clear: both;
  color: var(--text-color-muted, "#000");
  padding: 0 32px;
}

.BottomFooter small {
  color: #000;
  float: left;
  font-size: 14.4px;
  margin-right: 24px;
}

.PhoneNumberFooter {
  color: #ea4926;
  font-size: 18px;
  font-weight: 700;
}

.PhoneNumberFooter a {
  font-size: 18px;
  margin-left: 5px;
}

@media screen and (max-width: 425px) {
  .MobileHideSmall {
    display: none;
  }
}

.AppFooterColumns {
  color: #fff;
  font-size: 14px;
  margin: 0 auto;
  max-width: 1200px;
}

@media screen and (max-width: 768px) {
  .AppFooterColumns h6,
  .footAbout h6 {
    font-size: 20px !important;
    border-bottom: 1px solid #fff;
    padding-bottom: 10px;
    font-weight: bold;
  }
}

@media screen and (max-width: 640px) {
  .AppFooterColumns {
    padding: 10px 15px;
  }

  .AppFooterColumns .AppFooterColumns .FooterHeading {
    margin-bottom: 10px;
  }
}

.AppFooterCol {
  display: inline-block;
  padding: 0 16px;
  vertical-align: top;
}
.AppFooterCol:first-child {
  width: 34%;
}
.AppFooterCol:nth-child(2) {
  width: 34%;
}
.AppFooterCol:last-child {
  width: 32%;
}

@media screen and (max-width: 640px) {
  .AppFooterCol {
    width: 100% !important;
  }
  .FooterHeading {
    margin-top: 30px;
  }
}

.AppFooterColumns > .AppFooterCol:last-child svg,
.AppFooterColumns > .AppFooterCol:first-child svg {
  font-size: 24px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.AppFooterColumns > .AppFooterCol:first-child li {
  margin-bottom: 20px;
}

.AppFooterColumns h6,
.AppFooterColumns a {
  color: #fff;
}

.AppFooterColumns .FooterHeading {
  margin-bottom: 15px;
}

.footAbout {
  display: none;
}

@media screen and (max-width: 640px) {
  .footAbout {
    display: block;
    padding: 10px 35px;
  }
}

.footAbout a {
  color: #fff;
  display: block;
  font-size: 14px;
}

.footAbout h6 {
  color: #fff;
  font-size: 16px;
  margin-bottom: 15px;
}

.AppFooterCopyright {
  align-items: center;
  display: inline-flex;
  height: 56px;
  justify-content: center;
}

.AppFooterTap {
  align-items: center;
  border-bottom: 3px solid transparent;
  color: #212121;
  display: inline-flex;
  height: 56px;
  justify-content: center;
  padding: 0 10px;
  white-space: nowrap;
  text-align: center;
}

.AppFooterTap:hover {
  border-bottom-color: #e91e63;
}

.FooterIconLink {
  display: flex;
  font-size: 18px;
  line-height: 1.2;

  position: relative;
}

.FooterIconLinkIcon {
  width: 32px;
  position: relative;
  margin-right: 10px;
  left: 0;
  top: 6px;
}

.AppFooterColumns > .AppFooterCol:first-child li {
  line-height: 1.5em;
  margin-bottom: 5px;
  font-size: 18px;
}
