.AppHeader {
  background: linear-gradient(90deg, #f44336, #e91e63);
}
.AppHeaderNav {
  align-self: flex-end;
}

.AppHeaderNav ul {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}
.AppHeaderInner {
  align-items: center;
  display: flex;
}

.AppHeaderButton {
  align-items: center;
  color: #fff;
  display: flex;
  font-weight: 400;
  height: 56px;
  min-width: 56px;
  padding: 0 16px;
  position: relative;
  text-decoration: none;
}

.AppHeaderButton:hover {
  background-color: rgba(0, 0, 0, 0.06);
}

@media screen and (max-width: 939px) {
  .AppHeaderCityPicker {
    display: none;
  }
}

.AppHeaderCityPicker ul {
  display: flex;
}

.AppHeaderCityPicker a {
  font-size: 14.4px;
  line-height: 56px;
  padding: 0 16px;
}

.AppHeaderCityPicker svg {
  position: relative;
  top: -1px;
  left: -2px;
}
.AppHeaderLogo {
  align-items: center;
  padding-right: 16px;
  display: flex;
  height: 56px;
}

.AppHeaderLogo svg {
  position: relative;
  top: -3px;
}

.ButtonGroup {
  gap: 1rem;
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: flex-end;
}

.DestinationPicker li:not(:last-of-type) {
  border-bottom: 1px solid #e7e8e9;
  text-wrap: nowrap;
}

.DestinationPickerItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 56px;
  padding: 0 16px;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

.DestinationPickerItem:hover {
  background: #e7e8e9;
}

.DestinationPickerItem.Selected {
  background-color: #00bfa5 !important;
}

.DestinationPickerItem.Selected * {
  color: white;
}

@media screen and (max-width: 768px) {
  .AppHeader {
    padding: 0;
    position: sticky;
    top: 0;
    transition: 0.5s;
    z-index: 9000;
  }
}

.SubMenuParent {
  position: relative;
  z-index: 1000;
}

.SubMenu {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 100%;
  left: 100%;
  transform: translate(-50%, -1rem);
  z-index: -1;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
  padding: 1rem;
  overflow: hidden;
  width: auto;
  li {
    min-width: 220px;
    border-bottom: 0 !important;
  }
}

.SubMenuParent:focus .SubMenuArrow,
.SubMenuParent:focus-within .SubMenuArrow,
.SubMenuParent:hover .SubMenuArrow,
.SubMenuParent:focus .SubMenu,
.SubMenuParent:focus-within .SubMenu,
.SubMenuParent:hover .SubMenu {
  visibility: visible;
  opacity: 1;
  transform: translate(-42%, 0);
  z-index: 1;
}

.SubMenuArrow {
  visibility: hidden;
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
  box-shadow: 0 -3px 5px rgba(82, 95, 127, 0.04);
  z-index: 2;
  transition: transform 250ms;
}

.Accordion {
  list-style: none;
  padding: 0;
  margin: 0;
}

.AccordionHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f0f0f0;
  cursor: pointer;
  border: 1px solid #ddd;
  transition: background-color 0.3s ease;

  &.Expanded {
    background-color: #e91e63;
    color: #fff;
  }
}

.CitiesList {
  max-height: 0;
  padding: 0 0;
  overflow: hidden;
  background-color: #fafafa;
  border-left: 2px solid #ddd;
  opacity: 0;
  visibility: hidden;
  transition: max-height 0.3s ease, padding 0.3s ease, opacity 0.3s ease,
    visibility 0.3s ease;

  &.Show {
    max-height: 50vh;
    padding: 10px 0px;
    opacity: 1;
    visibility: visible;
    overflow: scroll;
  }
}

.CityItem {
  padding: 5px 0;
  cursor: pointer;
  transition: color 0.2s ease;
  border-bottom: 1px solid #dbdbdb;
  &:last-child {
    border-bottom: 0;
  }
  &:hover {
    color: #0070f3;
  }
}
