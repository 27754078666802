$element-height: 56px;
$element-width: 192px;
$border-radius: 10px;

.SearchBar {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  background-color: white;
  border: 1px solid #d9d9d9;
  border-radius: calc($element-height / 2);
  position: relative;
  width: 100%;
  overflow: hidden;
}

.SearchBarElement {
  position: relative;
  display: flex;
  align-items: center;
  height: $element-height;
  user-select: none;
  padding: 0px calc($element-height / 2) 0px calc($element-height / 2);
}

.SearchBarElement.Mobile {
  background-color: #e7e8e9;
}

.SearchBarElement:hover {
  cursor: pointer;
  background-color: #e7e8e9;
}

.SearchBarElement.Selected {
  font-weight: 700;
}

.SearchBarElement label,
.SearchBarElement span {
  font-size: 0.8rem;
  opacity: 0.65;
}

.SearchBarElement label {
  font-weight: 500;
  cursor: pointer;
}

.SearchBarElement label.Large,
.SearchBarElement span.Large {
  font-size: 1rem;
  opacity: 1;
}

.SearchBarElement form {
  line-height: 1.3rem;
}

.SearchButton {
  width: calc($element-height - 8px);
  height: calc($element-height - 8px);
  background-color: #f33f3a;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 4px;
  right: 4px;
}

.SearchButton svg {
  width: 22px;
  height: 22px;
  position: relative;
  top: 2px;
}

.SearchButton svg path {
  stroke: white;
}

.SearchButton:hover {
  background-color: red;
}

.SelectedLocation {
  background-color: #f33f3a !important;
}

.SelectedLocation * {
  color: white;
}

.SearchBarDialog {
  display: flex;
  z-index: 99999;
  border: 1px solid #d9d9d9;
  border-radius: $border-radius;
  // overflow: auto;
}

.SearchBarElementContainer {
  position: relative;
  box-sizing: border-box;
  flex: 1 1 0;
  &:hover:before {
    opacity: 0;
  }
}

.SearchBarElementContainer:not(:first-of-type)::before {
  content: "";
  display: block;
  position: absolute;
  height: 50%;
  top: 25%;
  left: -0.5px;
  width: 1px;
  background-color: #d9d9d9;
}

.SearchBarElementContainer:first-of-type .SearchBarElement {
  border-top-left-radius: calc($element-height / 2);
  border-bottom-left-radius: calc($element-height / 2);
}

.SearchBarElementContainer:last-of-type .SearchBarElement {
  border-top-right-radius: calc($element-height / 2);
  border-bottom-right-radius: calc($element-height / 2);
}

.SmallIcon {
  transform: translateY(2px);
}

.SmallIcon path {
  stroke: white;
}
.CountryCityPicker {
  width: auto;
}

.CountryCityPicker li:not(:last-of-type) {
  border-bottom: 1px solid #e7e8e9;
  text-wrap: nowrap;
}

.CountryPickerItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  height: 56px;
  min-width: 160px;
  padding: 0 16px;
  padding-right: 6px;
  &:hover {
    font-weight: bold;
  }
}

.CityPickerContainer {
  position: absolute;
  left: 100%;
  background: #fff;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
  border: 1px solid #d9d9d9;
}

.CityPickerItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 56px;
  padding: 0 16px;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  width: 220px;
}

.CityPickerItem:hover {
  background: #e7e8e9;
}

.CityPickerItem.Selected {
  background-color: #00bfa5 !important;
}

.CityPickerItem.Selected * {
  color: white;
}
