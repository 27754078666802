.Loader,
.Loader::after {
  border-radius: 50%;
  display: inline-block;
}

.Loader {
  animation: load8 1.1s infinite linear;
  border: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left-color: #fff;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  clear: both;
  margin: 0 auto;
  display: block;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
