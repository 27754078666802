// breakpoints

$S: 20em; // 320px / 16
$FM: 40em; // Conform to foundation 'medium' css breakpoint
$M: 47.5em; // 760px / 16
$FL: 64em; // Conform to foundation 'large' css breakpoint
$L: 75em; // 1200px / 16

$XL: 100em; //  1600px / 16

// media queries

@mixin MQ($canvas) {
  @if $canvas == S {
    @media only screen and (min-width: $S) {
      @content;
    }
  }
  @if $canvas == FM {
    @media only screen and (min-width: $FM) {
      @content;
    }
  } @else if $canvas == M {
    @media only screen and (min-width: $M) {
      @content;
    }
  } @else if $canvas == FL {
    @media only screen and (min-width: $FL) {
      @content;
    }
  } @else if $canvas == L {
    @media only screen and (min-width: $L) {
      @content;
    }
  } @else if $canvas == XL {
    @media only screen and (min-width: $XL) {
      @content;
    }
  }
}

$breakpoint-xs: 365px !default;
$breakpoint-sm: 400px !default;
$breakpoint-fm: 560px !default;
$breakpoint-md: 768px !default;
$breakpoint-ml: 840px !default;
$breakpoint-lg: 1000px !default;
$breakpoint-xl: 1200px !default;
$breakpoint-xxl: 1480px !default;

$breakpoints: (
  "xs": "only screen and (min-width: #{ $breakpoint-xs } )",
  "sm": "only screen and (min-width: #{ $breakpoint-sm } )",
  "fm": "only screen and (min-width: #{ $breakpoint-fm } )",
  "md": "only screen and (min-width: #{ $breakpoint-md } )",
  "ml": "only screen and (min-width: #{ $breakpoint-ml } )",
  "lg": "only screen and (min-width: #{ $breakpoint-lg } )",
  "xl": "only screen and (min-width: #{ $breakpoint-xl } )",
  "xxl": "only screen and (min-width: #{ $breakpoint-xxl } )",
) !default;

@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

$breakpoint-up: (
  "xs": "only screen and (min-width: #{ $breakpoint-xs } )",
  "sm": "only screen and (min-width: #{ $breakpoint-sm } )",
  "fm": "only screen and (min-width: #{ $breakpoint-fm } )",
  "md": "only screen and (min-width: #{ $breakpoint-md } )",
  "ml": "only screen and (min-width: #{ $breakpoint-ml } )",
  "lg": "only screen and (min-width: #{ $breakpoint-lg } )",
  "xl": "only screen and (min-width: #{ $breakpoint-xl } )",
  "xxl": "only screen and (min-width: #{ $breakpoint-xxl } )",
) !default;

$breakpoint-down: (
  "xs": "only screen and (max-width: calc( #{ $breakpoint-xs } - 1px ) )",
  "sm": "only screen and (max-width: calc( #{ $breakpoint-sm } - 1px ) )",
  "fm": "only screen and (max-width: calc( #{ $breakpoint-fm } - 1px ) )",
  "md": "only screen and (max-width: calc( #{ $breakpoint-md } - 1px ) )",
  "ml": "only screen and (max-width: calc( #{ $breakpoint-ml } - 1px ) )",
  "lg": "only screen and (max-width: calc( #{ $breakpoint-lg } - 1px ) )",
  "xl": "only screen and (max-width: calc( #{ $breakpoint-xl } - 1px ) )",
  "xxl": "only screen and (max-width: calc( #{ $breakpoint-xxl } - 1px ) )",
) !default;

@mixin mq-up($breakpoint: md) {
  @media #{map-get($breakpoint-up, $breakpoint)} {
    @content;
  }
}

@mixin mq-down($breakpoint: md) {
  @media #{map-get($breakpoint-down, $breakpoint)} {
    @content;
  }
}

@mixin responsive($class, $bp: $breakpoint-up) {
  #{$class} {
    @content;
  }
  @each $suffix, $value in $bp {
    @media #{$value} {
      #{$class}-#{$suffix} {
        @content;
      }
    }
  }
}

@include responsive(".display-none") {
  display: none;
}
