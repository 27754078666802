.Container {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1rem;
}

.ButtonGroup {
  display: flex;
  align-items: center;
  width: 96px;
  justify-content: space-between;
}

.IncrementButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid grey;
  width: 1.8rem;
  height: 1.8rem;
  font-size: 1.3rem;
  color: grey;
  background-color: transparent;
}

.IncrementButton:not(:disabled):hover {
  border: 1px solid black;
  cursor: pointer;
}

.IncrementButton:disabled:hover {
  cursor: not-allowed;
}

.IncrementButton:disabled {
  opacity: 25%;
}
