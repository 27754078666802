@import "../../../../styles/layout";
.TAFooterContainer {
  margin-top: 30px;

  border-top: 1px solid #ccc;
  padding-top: 30px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 30px;
}
.TAFooterContainerInner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @include mq-down(md) {
    flex-direction: column;
    align-items: center;
  }
}
.TAImage {
  position: relative;
  width: 70px;
  height: 70px;
  @include mq-down(md) {
    width: 67px;
    height: 67px;
  }
}
.FooterTA {
  display: flex;
  flex-direction: row;
  gap: 40px;
  @include mq-down(ml) {
    gap: 20px;
    font-size: 14px;
  }
  @include mq-down(md) {
    font-size: 16px;
    flex-direction: column;
  }
}
.FooterTACard {
  display: flex;
  flex-direction: column;
  &:hover {
    opacity: 0.7;
  }
}
.TACardWrapper {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
}
.TABorder {
  height: 70px;
  width: 2px;
  background: #000;
  margin-left: 5px;
}
.TAStats {
  // border-left: 2px solid #000;
  padding-left: 5px;
}
.TANightLife {
  color: #fff;
  padding: 5px;
  background-color: #03bfa5;
  border-radius: 20px;
  text-align: center;
  font-size: 12px;
  width: 120px;
}
.RightTA {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  strong {
    margin-bottom: 10px;
  }
  @include mq-down(md) {
    justify-content: center;
    strong {
      margin-top: 20px;
    }
  }
}
