.ModalContainer {
  padding-left: 24px;
  padding-right: 24px;
  min-height: CALC(100% - 78px);
  padding-top: 72px;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.Card {
  background-color: white;
  border-radius: 16px;
  padding: 16px;
  border: 1px solid rgb(195 195 195);
}

.Card .Collapsed {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.Label {
  font-weight: 700;
}

.ExpandedCardTitle {
  margin-bottom: 32px;
}

.BottomButton {
  padding: 16px;
  width: 100%;
  background-color: white;
  position: sticky;
  bottom: 0;
  border: 1px solid rgb(195 195 195);
}

.Title {
  position: absolute;
  color: #7f7f7f;
  padding-top: 12px;
  padding-right: 32px;
  width: 100%;
  text-align: center;
}

.ErrorMessage {
  color: red;
}

.CountryTileContainer {
  display: flex;
  gap: 10px;

  .CountryTile {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.1);
    border-style: solid;
    border-radius: 3px;
    border-width: 1px;
    box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px 0;
    display: flex;
    height: 73px;
    margin-bottom: 4px;
    overflow: hidden;
    padding: 0;
    text-align: left;
    width: 200px;
    font-weight: bold;
    text-align: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &.highlighted,
    &:hover {
      color: #fff;

      background: #ec1e79;
    }
  }
}
.LocationCityContainer {
  margin-top: 10px;
}
.LocationHeader {
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: 15px;
  padding: 5px 0px;
  padding-top: 0;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400;
}
