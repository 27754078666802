.CustomModal {
  position: relative;
  margin: 0 !important;
  padding: 0 !important;
  width: 510px;
  background-color: #d8d8d8 !important;

  @media only screen and (max-width: 640px) {
    width: 100%;
    height: 100% !important;
  }
}

.CustomRoot {
  z-index: 9999999999 !important;
}

.ModalContainer {
  padding-left: 24px;
  padding-right: 24px;
  min-height: CALC(100% - 78px);
  padding-top: 72px;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.EndComponent {
  padding: 16px;
  width: 100%;
  background-color: white;
  position: sticky;
  bottom: 0;
  border: 1px solid rgb(195 195 195);
}

.Title {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 16px;
}
